import { useEffect, useState, useCallback, useRef, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Header,
  BreadcrumbList,
  PrimaryButton,
  SiteModal,
} from '../../../components/common';
import {
  constants,
  Utils,
  DeviceTypeEnum,
  LicenseStatuses,
} from '../../../helpers';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
  setDeviceInformation,
  setAllMqttDataFromResponse,
  setDevicesSelectedLocation,
} from '../../../store/reducers/AccountReducer';
import '../../../assets/css/loader.scss';
import {
  getMQTTConnection,
  setLiveStream,
  setRemoteStream,
} from '../../../store/reducers/StreamingReducer';
import { usePoliciesStore } from '../../../store/policiesStore';
import { disconnectWithMQTT } from '../../../utils/connection/mqttConnection';
import useLicensesStore from '../../../store/LicensesStore';
import { IoIosArrowRoundUp, IoIosSearch } from 'react-icons/io';
import { HiOutlineChevronDown, HiOutlineChevronRight } from 'react-icons/hi';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { HiOutlineChevronLeft } from 'react-icons/hi2';
import moment from 'moment';
import SiteSpinner from '../../../components/common/SiteSpinner';
import { useNavigate } from 'react-router-dom';
import {
  setNVRDeviceData,
  setManufacturerSKUList,
} from '../../../store/reducers/NVRDeviceReducer';
import { useAppsStore } from '../../../store/AppsStore';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { getSelectedOrganization } from '../../../store/AccountStoreIDB';
import {
  setAllScannedDevices,
  setNVRScannedDevices,
} from '../../../store/NVRDeviceStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../store/reducers/OrganizationsReducer';
import './DiscoveredDevices.scss';
import { IoIosArrowRoundDown, IoMdClose } from 'react-icons/io';
import { RxCaretSort } from 'react-icons/rx';
import { IoSettingsOutline } from 'react-icons/io5';
import { GoPencil } from 'react-icons/go';
import { BsPlus } from 'react-icons/bs';
import { PiTrash } from 'react-icons/pi';
import { IoCloseCircle } from 'react-icons/io5';

import { ReactComponent as StepBottomIcon } from '../../../assets/images/step_bottom.svg';
import {
  CircleExclamation,
  IconEdit,
  IconFilter,
  IconRefresh,
} from '../../../assets/images';

import ResizableTable from './ResizableTable';
import DevicesFilters from './DevicesFilters';
import LocationAreaSelectorModal from './LocationAreaSelectorModal';
import RemoveDeviceModal from './RemoveDeviceModal';
import {
  channelsAPI,
  gatewaysAPI,
  removeChannelAPI,
  renameChannelAPI,
  updateDeviceAreaAPI,
  updateDeviceLoctaionAreaAPI,
} from './DevicesAPI';
import { sassTrue } from 'sass';
import EditDevices from './EditDevices';
import DeviceEditInputBox from './DeviceEditInputBox.tsx';
import ScanNetworkModal from './ScanNetworkModal';
import highlightWords from 'highlight-words';
import { TbMapPin } from 'react-icons/tb';
import SearchableDropDown from './SearchableDropDown.jsx';
import Checkbox from '../../../components/common/Checkbox';
import TableTooltip from '../../../components/common/TableTooltip';

const Devices = () => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const orgInfo = useSelector(getOrgInfo);
  const [searchText, setSearchText] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const [licensesData, setLicensesData] = useState([]);
  const [filteredDeviceData, setFilteredDeviceData] = useState([]);
  const mqttConnection = useSelector(getMQTTConnection);
  const [orgDetails, setOrgDetails] = useState();
  const [locationDetails, setLocationDetails] = useState(null);
  const { getLicenses, setLicenses, getExpiredLicensesInStore } =
    useLicensesStore();
  const navigate = useNavigate();
  const [showScanNetworkModal, setShowScanNetworkModal] = useState(false);
  const [gatewayDeviceData, setGatewayDeviceData] = useState(null);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderMore, setShowLoaderMore] = useState(false);
  const [discovredDevciesCount, setDiscovredDevciesCount] = useState(0);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const [isDiscoverPopupLoading, setIsDiscoverPopupLoading] = useState(null);
  const [expandedParent, setExpandedParent] = useState(null);
  const [isDisplayFiltersModal, setIsDisplayFiltersModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [renamingDevice, setRenamingDevice] = useState(null);
  const [isRenamingDevice, setIsRenamingDevice] = useState(false);
  const [isRemovingDevice, setIsRemovingDevice] = useState(false);
  const [isDisplayLocationAreaModal, setIsDisplayLocationAreaModal] =
    useState(false);
  const [isDisplayRemoveDeviceModal, setIsDisplayRemoveDeviceModal] =
    useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedDevicesAreas, setSelectedDevicesAreas] = useState([]);
  const [selectedDeviceToRemove, setSelectedDeviceToRemove] = useState(null);
  const [totalDevices, setTotalDevices] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isCheckForSearchCSS, setIsCheckForSearchCSS] = useState(false);
  const [deviceTypes] = useState([]);
  const [isD2CInSelectedDevices, setIsD2CInSelectedDevices] = useState(false);
  const [selectEligibleDevices, setSelectEligibleDevices] = useState([]);
  const [updateUserMessage, setUpdateUserMessage] = useState(null);
  const ref = useRef(null);

  const defaultPageDetails = {
    orderBy: 'deviceName',
    isAsc: true,
    page: 0,
    size: 100,
  };
  const newChildPageDetails = {
    orderBy: 'deviceName',
    isAsc: true,
    page: -1,
    size: 8,
  };
  const defaultFilters = {
    connectType: [],
    connectionStatus: [],
    deviceStatus: [],
    firmwareUpdate: [],
    applicationsLinked: [],
  };
  const [pageDetails, setPageDetails] = useState(defaultPageDetails);
  const [filtersData, setFiltersData] = useState(defaultFilters);

  let [selectedLocation, setSelectedLocation] = useState(null);
  const getApps = useAppsStore((state) => state.getApps);
  let appList = useAppsStore((state) => state.appList);
  appList = appList?.filter(
    (item) => item?.name !== constants.FLEXAI_PROD_LABEL,
  );
  const dispatch = useDispatch();
  let [customerOrgPolicies, setCustomerOrgPolicies] = useState([]);

  const breadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.url,
      title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
        ?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
      title: `${orgInfo?.orgName ? orgInfo?.orgName : ''}`,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
  ];

  const editModeBreadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.url,
      title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
        ?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
      title: `${orgInfo?.orgName ? orgInfo?.orgName : ''}`,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: 'Edit',
    },
  ];

  const paginatedHeaderList = [
    {
      displayName: '',
      id: 'arrow',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_NAME,
      id: 'deviceName',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_MODEL,
      id: 'modelNumber',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_SN,
      id: 'serialNo',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_STATUS,
      id: 'status',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_LOCATION,
      id: 'location',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_AREA,
      id: 'area',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_APPLICATIONS_LINKED,
      id: 'applications-linked',
    },
  ];

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustomerOrgPolicies(orgs?.[0]?.policies || []);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }

      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData, debouncedLoadCustomerOrgData]);

  useEffect(() => {
    localStorage.setItem('modalOpen', 0);
    fetchManufacturerData();
    fetchLicensesData();
    fetchDiscoveredDevicesCount();
    // fetchDeviceTypes();
    dispatch(setLiveStream(null));
    dispatch(setRemoteStream(null));
    dispatch(setDeviceInformation(null));
    if (mqttConnection) {
      disconnectWithMQTT();
    }
  }, []);

  useEffect(() => {
    const resetNVRScanData = async () => {
      await setNVRScannedDevices([]);
      await setAllScannedDevices([]);
    };
    resetNVRScanData();
  }, []);

  useEffect(() => {
    if (locationDetails?.length && locationDetails?.[0]?.orgId === orgId) {
      fetchGatewayData(searchText);
    }
  }, [JSON.stringify(pageDetails), JSON.stringify(filtersData)]);

  useEffect(() => {
    getApps(`partner/apps`);
  }, []);

  useEffect(() => {
    if (expandedParent?.deviceId) {
      fetchChannelDataByParentId();
    }
  }, [expandedParent?.deviceId]);

  useEffect(() => {
    if (selectedLocation?.locationId) {
      onClickReset();
    }
  }, [selectedLocation?.locationId]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0 });
    }
  }, [filtersData, selectedLocation, pageDetails]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [filteredDeviceData]);

  const findParentNAttachChilds = (
    parentList,
    children,
    childPageDetails,
    ifChild,
  ) => {
    if (!parentList || !children || !childPageDetails) return parentList;

    parentList.forEach((parent) => {
      if (parent?.deviceId === expandedParent?.deviceId) {
        children.forEach((child) => {
          addDeviceProperties(child, parent?.stepCount + 1);
        });

        parent.childDevices = childPageDetails?.page
          ? [...parent.childDevices, ...children]
          : children;

        parent.childPageDetails = childPageDetails;
      } else if (parent?.childDevices?.length) {
        parent = findParentNAttachChilds(
          parent.childDevices,
          children,
          childPageDetails,
          true,
        );
      }
    });

    if (ifChild) {
      return parentList[0];
    } else {
      return parentList;
    }
  };

  const addDeviceProperties = (device, stepCount) => {
    if (!device) return;

    const avlbleDeviceVersion = device?.properties?.['avlbl-device-ver'];
    const firmwareVersion = device?.properties?.['firmware-version'];
    const checkStatus =
      avlbleDeviceVersion && firmwareVersion
        ? avlbleDeviceVersion?.toLowerCase() !== firmwareVersion?.toLowerCase()
        : false;

    device['displayDeviceStatus'] = Utils.getDeviceStatus(
      device.deviceStatus,
      device.connectionStatus,
    );

    const expiredLicenses = getExpiredLicensesInStore();

    if (expiredLicenses?.length > 0) {
      device['isLicenseExpired'] = !!expiredLicenses
        ?.map((item) => item.deviceId)
        ?.includes(device?.deviceId);
    }
    device['isFirmwareUpgradeAvailable'] =
      device?.properties && device?.properties?.['avlbl-duclo-ver']
        ? device?.properties?.['avlbl-duclo-ver'] !==
          device?.properties?.['edge-app-version']
        : avlbleDeviceVersion
          ? avlbleDeviceVersion?.toLowerCase() !==
            firmwareVersion?.toLowerCase()
          : false;

    if (!device['isFirmwareUpgradeAvailable']) {
      device['isFirmwareUpgradeAvailable'] = checkStatus;
    }

    device['displayLicensesStatus'] = getLicensesStatus(device);
    device['displayArea'] = device?.location?.areaName;
    device['displayLocation'] = device?.location?.locationName;
    device['stepCount'] = stepCount;

    if (device?.apps) {
      if (!(constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO in device.apps)) {
        device.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO] = false;
      }
      if (
        !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI in device.apps)
      ) {
        device.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI] = false;
      }
      if (
        !(constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND in device.apps)
      ) {
        device.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND] =
          false;
      }
      if (!(constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS in device.apps)) {
        device.apps[constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS] = false;
      }
    }

    if (!device?.count) {
      device.count = 0;
    }
    return device;
  };

  const fetchGatewayData = async (search) => {
    setShowLoader(true);
    setSearchText(search?.trim());

    let gatewayAPIData = await gatewaysAPI(
      orgId,
      selectedLocation?.locationId,
      pageDetails,
      search?.trim(),
      filtersData,
      deviceTypes,
    );

    if (gatewayAPIData && !gatewayAPIData.userMsg && gatewayAPIData.devices) {
      gatewayAPIData.devices = gatewayAPIData?.pageNumber
        ? [...filteredDeviceData, ...gatewayAPIData.devices]
        : gatewayAPIData.devices;

      gatewayAPIData.devices.forEach((device) => {
        addDeviceProperties(device, 0);
      });

      setFilteredDeviceData(gatewayAPIData.devices);
      setTotalDevices(gatewayAPIData?.totalElements);
      setTotalPages(gatewayAPIData?.totalPages);
      setShowLoader(false);
      setShowLoaderMore(false);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(search?.trim());
    } else {
      setShowLoader(false);
      setShowLoaderMore(false);
    }
  };

  const fetchChannelDataByParentId = async () => {
    const channelAPIData = await channelsAPI(
      orgId,
      expandedParent,
      selectedLocation?.locationId,
      pageDetails,
      searchText,
      filtersData,
      deviceTypes,
    );

    if (channelAPIData && !channelAPIData?.userMsg && channelAPIData?.devices) {
      const parentList = findParentNAttachChilds(
        filteredDeviceData,
        channelAPIData.devices,
        {
          page: channelAPIData?.pageNumber ?? 0,
          totalPages: channelAPIData?.totalPages ?? 0,
        },
      );
      setFilteredDeviceData(parentList);

      if (selectedDevices?.includes(expandedParent?.deviceId)) {
        const tempExpandedDevice = structuredClone(expandedParent);
        const newChilds = channelAPIData.devices;

        tempExpandedDevice.childDevices = channelAPIData.pageNumber
          ? [...tempExpandedDevice.childDevices, ...newChilds]
          : newChilds;

        const checkList = forceSelectUnselectDevices(
          tempExpandedDevice,
          true,
          selectedDevices,
          true,
        );
        postCheckBoxClick(checkList);
      }

      setExpandedParent(null);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(searchText);
    } else {
      setShowLoader(false);
      setShowLoaderMore(false);
      setExpandedParent(null);
    }
  };

  const fetchDiscoveredDevicesCount = async () => {
    await axios
      .get(
        `/device/orgs/${orgId}/devices/discover/count`,
        Utils.requestHeader(),
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setDiscovredDevciesCount(responseData.count);
          }
        }
      });
  };

  const fetchManufacturerData = async () => {
    await axios
      .get('/device/manufacturerSkuBanks', Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData?.meta?.code === 200) {
          const responseData = resultData.data;
          setManufacturerData(responseData);
          dispatch(setManufacturerSKUList(responseData));
        } else {
          setManufacturerData([]);
          dispatch(setManufacturerSKUList([]));
        }
      });
  };

  const fetchLicensesData = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess && licensesLoadSuccess?.status === 'success') {
      setLicensesData(getLicenses());
    }
  };

  const getLicensesStatus = (device) => {
    const deviceLicense = licensesData.filter(
      (license) => license.deviceId === device.deviceId,
    )[0];

    if (
      deviceLicense?.expiryDate &&
      Utils.getDateDifferenceInDays(
        deviceLicense?.expiryDate,
        moment().valueOf(),
      ) <= 0
    ) {
      return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED;
    } else {
      if (
        deviceLicense?.licenseStatus ===
        LicenseStatuses.ASSIGNED_ACTIVATION_PENDING
      ) {
        return (
          constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
          ' ' +
          moment(deviceLicense?.activeDate).format('MMM Do YYYY')
        );
      } else if (
        deviceLicense?.licenseStatus !== LicenseStatuses.UNASSIGNED &&
        deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense?.expiryDate,
          moment().valueOf(),
        ) <= deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense?.expiryDate,
          moment().valueOf(),
        ) >= 0
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON;
      } else if (
        deviceLicense?.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED ||
        deviceLicense?.licenseStatus === LicenseStatuses.UNASSIGNED
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE;
      } else if (deviceLicense?.licenseStatus === LicenseStatuses.ACTIVE) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE;
      } else if (deviceLicense?.licenseStatus === LicenseStatuses.UNASSIGNED) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      } else {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      }
    }
  };

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          return await response.json();
        } else {
          return {};
        }
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  };

  const showChannelDetails = async (data) => {
    if (!data) return;

    const capDetails = await getCapabiltiesForDevice(data);
    localStorage.setItem('localStoreProperty', JSON.stringify(data.properties));
    dispatch(setDeviceInformation({ ...data, capDetails: capDetails }));

    if (data.deviceId) {
      navigate(
        `/devices/channel-details.html?deviceId=${data.deviceId}&orgId=${orgDetails?.orgId}`,
      );
    }
  };

  const handleScanNetworkForDeviceClick = async (device) => {
    setIsDiscoverPopupLoading(device?.deviceId);
    // await setNVRScannedDevices([]);
    // await setAllScannedDevices([]);
    axios
      .get(
        `device/orgs/${orgDetails?.orgId ? orgDetails?.orgId : orgId}/devices/${device?.deviceId}/v2`,
        Utils.requestHeader(),
      )
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setIsDiscoverPopupLoading(null);
            dispatch(setAllMqttDataFromResponse(null));
            setShowScanNetworkModal(true);
            setGatewayDeviceData(responseData);
            dispatch(
              setNVRDeviceData({
                ...responseData,
                channelCount: manufacturerData?.find(
                  (item) => item.model === responseData.modelNumber,
                )?.channelCount,
                childDevices: device.childDevices,
              }),
            );
            await setNVRScannedDevices([]);
            await setAllScannedDevices([]);
          } else {
            setIsDiscoverPopupLoading(null);
            console.log('Error- ', responseMeta.userMsg);
          }
        }
      });
  };

  const onCloseScanNetworkPopup = () => {
    setShowScanNetworkModal(false);
    fetchGatewayData(searchText);
  };

  // for mobile responsive device
  const onClickRemoveDevice = (device) => {
    setSelectedDeviceToRemove(device);
    setIsDisplayRemoveDeviceModal(true);
  };

  const renameRecursive = (device, newName) => {
    if (!device) return;

    if (device?.deviceId === renamingDevice?.deviceId) {
      device.deviceName = newName;
      return;
    }

    if (Array.isArray(device?.childDevices) && device.childDevices.length) {
      device.childDevices.forEach((childDevice) => {
        renameRecursive(childDevice, newName);
      });
    }
  };

  const onRenameDevice = async (newName) => {
    if (!newName) {
      setRenamingDevice(null);
      return;
    }
    setIsRenamingDevice(true);

    let updateAPIData = await renameChannelAPI(orgId, renamingDevice, newName);

    setIsRenamingDevice(false);
    if (updateAPIData?.deviceName) {
      const rawFilteredDeviceData = structuredClone(filteredDeviceData);
      rawFilteredDeviceData.forEach((device) => {
        renameRecursive(device, updateAPIData?.deviceName);
      });
      setFilteredDeviceData(rawFilteredDeviceData);
      setRenamingDevice(null);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(searchText);
    }
  };

  const onRemoveDevice = async () => {
    setIsDisplayRemoveDeviceModal(false);
    setIsRemovingDevice(true);
    let updateAPIResponse = await removeChannelAPI(
      orgId,
      selectedDeviceToRemove,
    );

    setIsRemovingDevice(false);
    if (updateAPIResponse?.code === 200 || updateAPIResponse?.code === 2303) {
      if (pageDetails?.page) {
        setPageDetails(defaultPageDetails);
      } else {
        fetchGatewayData(searchText);
      }
      setSelectedDeviceToRemove(null);
    }
  };

  const collapseRecursive = (device, deviceCollapsed) => {
    if (!device || !deviceCollapsed) return;

    if (device.deviceId === deviceCollapsed.deviceId) {
      device.isExpanded = !device.isExpanded;
      device.childDevices = [];
      return;
    }

    if (Array.isArray(device.childDevices) && device.childDevices.length) {
      device.childDevices.forEach((childDevice) => {
        collapseRecursive(childDevice, deviceCollapsed);
      });
    }
  };

  const onCollapseExpandDevice = (deviceCollapsed, forceStatus) => {
    let rawFilteredDeviceData = structuredClone(filteredDeviceData);
    let response;

    if (forceStatus) {
      if (deviceCollapsed?.stepCount) {
        response = midDevicesForceUpdate(
          rawFilteredDeviceData,
          deviceCollapsed,
          selectedDevices,
          false,
        );
      } else {
        response = topDevicesForceUpdate(
          rawFilteredDeviceData,
          deviceCollapsed,
          selectedDevices,
          false,
        );
      }
      rawFilteredDeviceData = response?.rawFilteredDeviceData;

      const newList = [...new Set(response?.checkList)];
      postCheckBoxClick(newList);
    }

    rawFilteredDeviceData.forEach((device) => {
      collapseRecursive(device, deviceCollapsed);
    });

    setFilteredDeviceData(rawFilteredDeviceData);

    if (deviceCollapsed && !deviceCollapsed.isExpanded) {
      deviceCollapsed.childPageDetails = newChildPageDetails;
      setExpandedParent(deviceCollapsed);
    }
  };

  const onSort = (heading) => {
    const rawPageDetails = structuredClone(pageDetails);

    if (rawPageDetails.orderBy === heading?.id) {
      rawPageDetails.isAsc = !pageDetails.isAsc;
    } else {
      rawPageDetails.isAsc = sassTrue;
    }
    rawPageDetails.orderBy = heading?.id;
    rawPageDetails.page = 0;
    setPageDetails(rawPageDetails);
    resetSelectedDevices();
  };

  const onSelectLocationHandler = (locationData) => {
    if (!locationData) return;
    const tempLocation = structuredClone(locationData);
    tempLocation.orgId = orgId;
    setSelectedLocation(tempLocation);
    dispatch(setDevicesSelectedLocation(tempLocation));
  };

  const onchangePageNo = (pageNo) => {
    const rawPageDetails = structuredClone(pageDetails);
    rawPageDetails.page = pageNo;
    setPageDetails(rawPageDetails);
  };

  const onClickReset = () => {
    setUpdateUserMessage(null);
    resetSelectedDevices();
    setSearchText('');
    setSearchedText('');
    if (
      JSON.stringify(pageDetails) === JSON.stringify(defaultPageDetails) &&
      JSON.stringify(filtersData) === JSON.stringify(defaultFilters)
    ) {
      fetchGatewayData('');
    } else {
      setPageDetails(defaultPageDetails);
      setFiltersData(defaultFilters);
    }
  };

  useEffect(() => {
    const list = [];
    filteredDeviceData?.forEach((device) => {
      if (
        device &&
        device.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        device?.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        getCustomerOrgPolicies()?.manage_device_settings
      ) {
        list.push(device?.deviceId);
      }
    });
    setSelectEligibleDevices(list);
  }, [filteredDeviceData, isEditMode]);

  const onSelectAllDevices = () => {
    const list = [];
    filteredDeviceData?.forEach((device) => {
      if (
        device &&
        device.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        device?.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        getCustomerOrgPolicies()?.manage_device_settings
      ) {
        list.push(device?.deviceId);
        forceSelectUnselectDevices(device, true, list);
      }
    });
    const newList = [...new Set(list)];
    setSelectedDevices(newList);
    checkAnyD2CInSelectedDevices(newList);
    resetPartialSelectionStatus();
    setUpdateUserMessage(null);
  };

  const statusOfAllDevicesSelected = () => {
    const list = [];
    filteredDeviceData?.forEach((device) => {
      if (
        device &&
        device.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        device?.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        getCustomerOrgPolicies()?.manage_device_settings &&
        selectedDevices.includes(device?.deviceId)
      ) {
        list.push(device?.deviceId);
      }
    });
    return (
      selectEligibleDevices?.length &&
      list?.length === selectEligibleDevices?.length
    );
  };

  const resetSelectedDevices = () => {
    setSelectedDevices([]);
    setIsD2CInSelectedDevices(false);
  };

  const resetPartialSelectionStatus = () => {
    const parcialResetRecursive = (rawDevice) => {
      if (!rawDevice) return;

      rawDevice.partial = false;

      if (
        Array.isArray(rawDevice?.childDevices) &&
        rawDevice.childDevices.length
      ) {
        rawDevice.childDevices.forEach((childDevice) => {
          parcialResetRecursive(childDevice);
        });
      }
      return rawDevice;
    };

    const rawFilteredDeviceData = structuredClone(filteredDeviceData);

    rawFilteredDeviceData.forEach((rawDevice) => {
      parcialResetRecursive(rawDevice);
    });
    setFilteredDeviceData(rawFilteredDeviceData);
  };

  const findRecursiveDeviceByDeviceId = (
    device,
    selectedDeviceId,
    filteredDevice,
  ) => {
    if (device?.deviceId === selectedDeviceId) {
      filteredDevice = device;
    }
    if (device?.childDevices?.length && !filteredDevice) {
      device?.childDevices?.forEach((childDevice) => {
        filteredDevice = findRecursiveDeviceByDeviceId(
          childDevice,
          selectedDeviceId,
          filteredDevice,
        );
      });
    }
    return filteredDevice;
  };

  const checkAnyD2CInSelectedDevices = (selectedDevices) => {
    let status = false;
    const areaList = [];
    selectedDevices.forEach((selectedDeviceId) => {
      let filteredDevice = null;
      filteredDeviceData.forEach((device) => {
        if (!filteredDevice) {
          filteredDevice = findRecursiveDeviceByDeviceId(
            device,
            selectedDeviceId,
            filteredDevice,
          );
        }
      });
      if (filteredDevice) {
        areaList.push(filteredDevice?.areaId);
        status =
          status ||
          ((filteredDevice?.deviceType?.toUpperCase() ===
            DeviceTypeEnum.IPCam ||
            filteredDevice.deviceType.toLowerCase() === 'nwc' ||
            filteredDevice.deviceType.toLowerCase() === 'onvifcam') &&
            filteredDevice?.stepCount) ||
          filteredDevice.deviceType === DeviceTypeEnum.WAVE;
      }
    });
    setIsD2CInSelectedDevices(status);
    setSelectedDevicesAreas(areaList);
  };

  const forceSelectUnselectDevices = (
    device,
    status,
    checkList,
    fromExpandClick,
  ) => {
    if (
      (device?.isExpanded || fromExpandClick) &&
      device?.childDevices?.length
    ) {
      device?.childDevices?.forEach((childDevice) => {
        if (
          childDevice?.connectionStatus ===
            constants.DEVICES_ONLINE_CONNECTION_STATUS &&
          childDevice?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
        ) {
          if (status) {
            checkList.push(childDevice?.deviceId);
          } else {
            const index = checkList.indexOf(childDevice?.deviceId, 0);

            if (index > -1) {
              checkList.splice(index, 1);
            }
          }
          if (childDevice?.childDevices?.length) {
            forceSelectUnselectDevices(
              childDevice,
              status,
              checkList,
              fromExpandClick,
            );
          }
        }
      });
      return checkList;
    } else {
      return checkList;
    }
  };

  const topDevicesForceUpdate = (
    rawFilteredDeviceData,
    targetedDevice,
    checkList,
    checked,
  ) => {
    const updateDeviceProps = (oDevice, oCheckList, oChecked) => {
      if (
        oDevice &&
        oDevice.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        oDevice.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
      ) {
        if (oChecked) {
          oCheckList.push(oDevice.deviceId);
        } else {
          const index = oCheckList.indexOf(oDevice.deviceId, 0);
          if (index > -1) {
            oCheckList.splice(index, 1);
          }
        }
        oDevice.partial = false;
      }

      return oDevice;
    };

    const loop = (pGrandParent, pCheckList, pChecked) => {
      if (pGrandParent?.childDevices?.length) {
        pGrandParent.childDevices.forEach((qChild) => {
          loop(qChild, pCheckList, pChecked);
        });
      }

      return updateDeviceProps(pGrandParent, pCheckList, pChecked);
    };

    rawFilteredDeviceData.forEach((grandParent) => {
      if (targetedDevice?.deviceId === grandParent?.deviceId) {
        grandParent = loop(grandParent, checkList, checked);
      }
    });

    return {
      rawFilteredDeviceData,
      checkList,
    };
  };

  const bottomDevicesForceUpdate = (
    rawFilteredDeviceData,
    targetedDevice,
    checkList,
    checked,
  ) => {
    const updateDeviceProps = (oParent, oDevice, oCheckList, oChecked) => {
      if (oParent?.childDevices?.length) {
        let statusCount = 0;
        let offlineCount = 0;

        oParent?.childDevices?.forEach((eChild) => {
          if (eChild && eChild.deviceId === oDevice?.deviceId) {
            eChild.partial = false;

            if (oChecked) {
              statusCount = statusCount + 1;
              oCheckList?.push(eChild.deviceId);
            } else {
              const index = oCheckList?.indexOf(eChild.deviceId, 0);

              if (index > -1) {
                oCheckList?.splice(index, 1);
              }
            }
          } else if (oCheckList?.includes(eChild?.deviceId)) {
            statusCount = statusCount + 1;
          } else if (
            eChild?.connectionStatus ===
            constants.DEVICES_OFFLINE_CONNECTION_STATUS
          ) {
            offlineCount = offlineCount + 1;
          }
        });

        let totalCount = offlineCount + statusCount;

        if (oParent?.childDevices?.length > totalCount && statusCount) {
          oParent.partial = true;
          const index = oCheckList?.indexOf(oParent.deviceId, 0);

          if (index > -1) {
            oCheckList?.splice(index, 1);
          }
        } else if (oParent?.childDevices?.length === totalCount) {
          oCheckList?.push(oParent?.deviceId);
          oParent.partial = false;
        } else if (oParent?.childDevices?.length && !statusCount) {
          oParent.partial = false;
          const index = oCheckList?.indexOf(oParent.deviceId, 0);

          if (index > -1 && !oChecked) {
            oCheckList?.splice(index, 1);
          }
        }

        return oParent;
      }
    };

    const loop = (gGrandParent, gDevice, gCheckList, gChecked) => {
      if (
        (gDevice?.parentId === gGrandParent?.deviceId ||
          gDevice?.gatewayId === gGrandParent?.deviceId) &&
        gDevice?.stepCount - gGrandParent?.stepCount === 1 &&
        gGrandParent?.childDevices?.length
      ) {
        gGrandParent = updateDeviceProps(
          gGrandParent,
          gDevice,
          gCheckList,
          gChecked,
        );
      } else if (
        (gDevice?.parentId === gGrandParent?.deviceId ||
          gDevice?.gatewayId === gGrandParent?.deviceId) &&
        gGrandParent?.childDevices?.length &&
        gDevice?.stepCount - gGrandParent?.stepCount > 1 &&
        gGrandParent?.childDevices?.length
      ) {
        gGrandParent?.childDevices?.forEach((qChild) => {
          if (
            gDevice?.parentId === qChild?.deviceId ||
            gDevice?.gatewayId === qChild?.deviceId
          ) {
            qChild = loop(qChild, gDevice, gCheckList, gChecked);
          }
        });
        gGrandParent = updateDeviceProps(
          gGrandParent,
          gDevice,
          gCheckList,
          gChecked,
        );
      }
      return gGrandParent;
    };
    rawFilteredDeviceData.forEach((grandParent) => {
      if (
        targetedDevice?.parentId === grandParent?.deviceId ||
        targetedDevice?.gatewayId === grandParent?.deviceId
      ) {
        grandParent = loop(grandParent, targetedDevice, checkList, checked);
      }
    });
    return {
      rawFilteredDeviceData,
      checkList,
    };
  };

  const midDevicesForceUpdate = (
    rawFilteredDeviceData,
    targetedDevice,
    checkList,
    checked,
  ) => {
    const updateParentDeviceProps = (oParent, oCheckList, oChecked) => {
      if (oParent?.childDevices?.length) {
        let statusCount = 0;
        let offlineCount = 0;

        oParent?.childDevices?.forEach((eChild) => {
          if (oCheckList?.includes(eChild?.deviceId)) {
            statusCount = statusCount + 1;
          } else if (
            eChild?.connectionStatus ===
            constants.DEVICES_OFFLINE_CONNECTION_STATUS
          ) {
            offlineCount = offlineCount + 1;
          }
        });

        let totalCount = offlineCount + statusCount;

        if (oParent?.childDevices?.length > totalCount && statusCount) {
          oParent.partial = true;
          const index = oCheckList?.indexOf(oParent.deviceId, 0);

          if (index > -1) {
            oCheckList?.splice(index, 1);
          }
        } else if (oParent?.childDevices?.length === totalCount) {
          oCheckList?.push(oParent?.deviceId);
          oParent.partial = false;
        } else if (oParent?.childDevices?.length && !statusCount) {
          oParent.partial = false;
          const index = oCheckList?.indexOf(oParent.deviceId, 0);

          if (index > -1 && !oChecked) {
            oCheckList?.splice(index, 1);
          }
        }
        return oParent;
      }
    };

    const updateDeviceProps = (oDevice, oCheckList, oChecked) => {
      if (
        oDevice &&
        oDevice.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        oDevice.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
      ) {
        if (oChecked) {
          oCheckList.push(oDevice.deviceId);
        } else {
          const index = oCheckList.indexOf(oDevice.deviceId, 0);

          if (index > -1) {
            oCheckList.splice(index, 1);
          }
        }
        oDevice.partial = false;
      }

      return oDevice;
    };

    const loop = (qGrandParent, qCheckList, qChecked) => {
      if (qGrandParent?.childDevices?.length) {
        qGrandParent?.childDevices.forEach((qChild) => {
          qChild = loop(qChild, qCheckList, qChecked);
        });
        qGrandParent = updateDeviceProps(qGrandParent, qCheckList, qChecked);
      } else {
        qGrandParent = updateDeviceProps(qGrandParent, qCheckList, qChecked);
      }

      return qGrandParent;
    };

    const outerLoop = (pGrandParent, pDevice, pCheckList, pChecked) => {
      pGrandParent?.childDevices?.forEach((qChild) => {
        if (qChild?.deviceId === pDevice?.deviceId) {
          qChild = loop(qChild, pCheckList, pChecked);
        }
      });
      return pGrandParent;
    };

    rawFilteredDeviceData.forEach((grandParent) => {
      if (
        targetedDevice?.parentId === grandParent?.deviceId ||
        targetedDevice?.gatewayId === grandParent?.deviceId
      ) {
        grandParent = outerLoop(
          grandParent,
          targetedDevice,
          checkList,
          checked,
        );

        grandParent = updateParentDeviceProps(grandParent, checkList, checked);
      }
    });

    return {
      rawFilteredDeviceData,
      checkList,
    };
  };

  const onChanegCheckBox = (e, device) => {
    const { checked } = e.target;
    let checkList = structuredClone(selectedDevices);
    if (checked) {
      checkList.push(device?.deviceId);
    } else {
      const index = checkList.indexOf(device?.deviceId, 0);
      if (index > -1) {
        checkList.splice(index, 1);
      }
    }
    let rawFilteredDeviceData = structuredClone(filteredDeviceData);
    let response = null;
    if (device?.stepCount === 0) {
      response = topDevicesForceUpdate(
        rawFilteredDeviceData,
        device,
        checkList,
        checked,
      );
    } else if (device?.stepCount && device?.childDevices) {
      response = midDevicesForceUpdate(
        rawFilteredDeviceData,
        device,
        checkList,
        checked,
      );
    } else {
      response = bottomDevicesForceUpdate(
        rawFilteredDeviceData,
        device,
        checkList,
        checked,
      );
    }
    setFilteredDeviceData(response?.rawFilteredDeviceData);
    const newList = [...new Set(response?.checkList)];
    postCheckBoxClick(newList);
  };

  const postCheckBoxClick = (checkList) => {
    const newList = [...new Set(checkList)];
    setSelectedDevices(newList);
    checkAnyD2CInSelectedDevices(newList);
    setUpdateUserMessage(null);
  };

  const updateDeviceLocationNArea = async (location, area) => {
    setShowLoader(true);
    let locationAreaAPIData = null;
    if (isD2CInSelectedDevices) {
      locationAreaAPIData = await updateDeviceAreaAPI(
        orgId,
        location,
        area,
        selectedDevices,
      );
    } else {
      locationAreaAPIData = await updateDeviceLoctaionAreaAPI(
        orgId,
        location,
        area,
        selectedDevices,
      );
    }

    if (locationAreaAPIData?.devices) {
      setUpdateUserMessage(locationAreaAPIData?.devices);
      if (JSON.stringify(pageDetails) === JSON.stringify(defaultPageDetails)) {
        fetchGatewayData(searchText);
      } else {
        setPageDetails(defaultPageDetails);
      }
      if (!locationAreaAPIData?.devices?.failed?.length) {
        resetSelectedDevices();
      }
    } else {
      setShowLoader(false);
    }
  };

  const displayDataWithTooltip = (value, search) => {
    let chunks = [];
    if (search && value) {
      chunks = highlightWords({
        text: value,
        query: searchedText,
        matchExactly: true,
      });
    }
    return (
      <TableTooltip text={value}>
        <div className="ellipsis-cell">
          <span className={search ? 'searchStyles' : ''}>
            {searchedText && search
              ? chunks.map(({ text, match, key }) =>
                  match ? (
                    <span className="searched-text" key={key}>
                      {text}
                    </span>
                  ) : (
                    <span key={key}>{text}</span>
                  ),
                )
              : value}
          </span>
        </div>
      </TableTooltip>
    );
  };

  const getClassName = (deviceApps, itemId, deviceStatus) => {
    return deviceApps?.[
      itemId === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
        ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
        : itemId
    ]
      ? 'appActiveWithoutOnCloud'
      : 'appDisableWithoutOnCloud';
  };

  const isNoClaimedApp = (apps) => {
    return Object.values(apps).every((value) => value === false);
  };

  const displayApplicationsColumnDetails = (device) => {
    const renderIcon = () => {
      const isWaveDevice =
        device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE;
      const isRenderIcon = !device?.stepCount && !isWaveDevice;
      const isPolicyDeleteEnabled = customerOrgPolicies?.filter(
        (item) => item.policyName === 'delete_device',
      );

      if (
        !isEditMode &&
        isRenderIcon &&
        getCustomerOrgPolicies()?.manage_device_settings
      ) {
        return (
          <IoSettingsOutline
            size={24}
            width={24}
            height={24}
            className={'setting-icon'}
            color="#0000009C"
            onClick={(e) => {
              e.preventDefault();
              showChannelDetails(device);
            }}
          />
        );
      }

      if (!isPolicyDeleteEnabled || !isEditMode) return null;

      if (
        isRemovingDevice &&
        selectedDeviceToRemove?.deviceId === device?.deviceId
      ) {
        return (
          <span className="margin-lt-20">
            <SiteSpinner
              width="16px"
              height="16px"
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_black')}
            />
          </span>
        );
      }

      if (isNoClaimedApp(device?.apps)) {
        return (
          <span className="hover-icons">
            <PiTrash
              size={24}
              className={'setting-icon'}
              color="#0000009C"
              onClick={() => onClickRemoveDevice(device)}
              role="button"
            />
          </span>
        );
      }
    };

    return (
      <div className="d-flex align-center justify-content-between">
        {device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ||
        device?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER ||
        device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE ? (
          <PrimaryButton
            className={`device-btn-list btn-primary ${
              isEditMode ? 'hide' : ''
            }`}
            type="button"
            width="165px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white',
            )}
            fontSize="14px"
            height="32px"
            fontWeight="400"
            borderRadius="4px"
            disabled={
              device?.deviceStatus ===
                constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
              device?.deviceStatus ===
                constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS ||
              device?.connectionStatus ===
                constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
              !getCustomerOrgPolicies()?.install_device
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleScanNetworkForDeviceClick(device);
            }}
            loader={isDiscoverPopupLoading === device?.deviceId}
          >
            {constants.DISCOVERED_DEVICES_SCAN_BUTTON_TEXT}
          </PrimaryButton>
        ) : (
          <div>
            {appList?.map((item, index) => (
              <Fragment key={item.id}>
                {Object.keys(device?.apps).includes(
                  item.id ===
                    constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                    ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                    : item.id,
                ) && (
                  <TableTooltip text={item.name}>
                    <img
                      alt=""
                      className={`deviceIcon ${getClassName(
                        device?.apps,
                        item.id,
                        device?.deviceStatus,
                      )} ${index + 1 === appList?.length ? 'mx-0' : ''}`}
                      src={
                        device.apps[
                          item.id ===
                          constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD
                            ? constants.DEVICES_LIST_APPLICATION_APP_TYPE_VMS
                            : item.id
                        ]
                          ? item.icon
                          : item.gicon
                      }
                    />
                  </TableTooltip>
                )}
              </Fragment>
            ))}
          </div>
        )}
        {renderIcon()}
      </div>
    );
  };
  const displayModalColumnDetails = (device) => {
    return (
      <div className="cell-flex-container">
        <div className="cell-tooltip-wrapper">
          {displayDataWithTooltip(device?.modelNumber, true)}
        </div>
        {device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
        device?.isFirmwareUpgradeAvailable &&
        device?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
          <TableTooltip text="Firmware update available">
            <span className="fw-update-icon">FW</span>
          </TableTooltip>
        ) : null}
      </div>
    );
  };

  const displayDeviceStatusColumnDetails = (device) => {
    return (
      <TableTooltip
        Component={Utils.getDeviceStatusWithDot(device?.displayDeviceStatus)}
      >
        {Utils.getDeviceStatusWithDot(device?.displayDeviceStatus)}
      </TableTooltip>
    );
  };

  const displayRowAccordian = (device) => {
    return (
      <>
        {expandedParent?.deviceId === device?.deviceId &&
        !device?.childDevices?.length ? (
          <span className={`d-flex`}>
            <SiteSpinner
              width="16px"
              height="16px"
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_black')}
            />
          </span>
        ) : device?.isExpanded ? (
          <HiOutlineChevronDown
            size={20}
            color="#747E87"
            className={` ${
              device?.count > 2 ||
              (device?.count > 1 &&
                (device?.deviceType?.toUpperCase() ===
                  DeviceTypeEnum.DMSERVER ||
                  device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE))
                ? ''
                : 'hide'
            }`}
            onClick={() => {
              onCollapseExpandDevice(device, true);
            }}
            role="button"
          />
        ) : (
          <HiOutlineChevronRight
            size={20}
            color="#747E87"
            className={`${
              device?.count > 2 ||
              (device?.count > 1 &&
                (device?.deviceType?.toUpperCase() ===
                  DeviceTypeEnum.DMSERVER ||
                  device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE))
                ? ''
                : 'hide'
            }`}
            onClick={() => {
              onCollapseExpandDevice(device, false);
            }}
            role="button"
          />
        )}
      </>
    );
  };

  const displayRowEditCheckBox = (device) => {
    return (
      <>
        {isEditMode ? (
          <td>
            <div className="cell-checkbox-container">
              {(device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ||
                device?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER ||
                device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE) &&
              device?.partial &&
              !selectedDevices.includes(device?.deviceId) ? (
                <span
                  className="edit-checkbox"
                  role="button"
                  onClick={() =>
                    onChanegCheckBox(
                      {
                        target: {
                          checked: !selectedDevices.includes(device?.deviceId),
                        },
                      },
                      device,
                    )
                  }
                >
                  <input
                    type="checkbox"
                    checked={device?.partial}
                    className={`edit-checkbox
                  ${
                    device?.connectionStatus !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                    device?.deviceStatus !==
                      constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                    !getCustomerOrgPolicies()?.manage_device_settings
                      ? 'disabled'
                      : ''
                  }
                `}
                    disabled={
                      device?.connectionStatus !==
                        constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                      device?.deviceStatus !==
                        constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                      !getCustomerOrgPolicies()?.manage_device_settings
                    }
                    onChange={(e) =>
                      onChanegCheckBox(
                        {
                          target: {
                            checked: !selectedDevices.includes(
                              device?.deviceId,
                            ),
                          },
                        },
                        device,
                      )
                    }
                    role="button"
                  />
                  <span className="edit-inner-checkbox"></span>
                </span>
              ) : (
                <Checkbox
                  checked={selectedDevices.includes(device?.deviceId)}
                  onClick={(e) => onChanegCheckBox(e, device)}
                  disabled={
                    device?.connectionStatus !==
                      constants.DEVICES_ONLINE_CONNECTION_STATUS ||
                    device?.deviceStatus !==
                      constants.DEVICES_CLAIMED_DEVICE_STATUS ||
                    !getCustomerOrgPolicies()?.manage_device_settings
                  }
                />
              )}
            </div>
          </td>
        ) : null}
      </>
    );
  };

  const displayDeviceNameColumnDetails = (device) => {
    const chunks = highlightWords({
      text: device?.deviceName,
      query: searchedText,
      matchExactly: true,
    });
    return (
      <div className="cell-flex-container">
        {device?.stepCount ? (
          <StepBottomIcon
            size={20}
            color="#0000004A"
            className={`margin-rt`}
            style={{
              marginLeft:
                device?.stepCount > 1 ? `${device?.stepCount * 20}px` : '',
            }}
          />
        ) : null}
        {Utils.getDeviceIcon(device)}
        {isEditMode && renamingDevice?.deviceId === device?.deviceId ? (
          <DeviceEditInputBox
            deviceName={device?.deviceName}
            isRenamingDevice={isRenamingDevice}
            onRenameDevice={onRenameDevice}
          />
        ) : (
          <div className="cell-tooltip-wrapper">
            <TableTooltip text={device?.deviceName}>
              <div className="ellipsis-cell">
                <span className={'searchStyles'}>
                  {chunks.map(({ text, match, key }) =>
                    match ? (
                      <span className="searched-text" key={key}>
                        {text}
                      </span>
                    ) : (
                      <span key={key}>{text}</span>
                    ),
                  )}
                </span>
              </div>
            </TableTooltip>
          </div>
        )}
        {isEditMode &&
        renamingDevice?.deviceId !== device?.deviceId &&
        device?.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
        device?.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS ? (
          <span className="hover-icons">
            <GoPencil
              size={16}
              role="button"
              color="#0000007D"
              className={'mx-1 mb-1'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setRenamingDevice(device);
              }}
            />
          </span>
        ) : null}
      </div>
    );
  };

  const displayDeviceRowDetails = (device) => {
    return (
      <tr
        className={`row-bg ${device?.isExpanded ? 'expanded-parent-row' : ''}`}
      >
        <td>{displayRowAccordian(device)}</td>
        {isEditMode ? displayRowEditCheckBox(device) : null}
        <td
          className={`
            ${
              device?.count > 2 ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE ||
              device?.stepCount === 0
                ? 'parent-name'
                : 'device-name-color'
            }
             ${
               isEditMode && renamingDevice?.deviceId === device?.deviceId
                 ? 'paginated-device-name'
                 : isEditMode
                   ? 'truncated-device-name'
                   : ''
             }
          `}
        >
          {displayDeviceNameColumnDetails(device)}
        </td>
        <td>{displayModalColumnDetails(device)}</td>
        <td>{displayDataWithTooltip(device?.serialNo, true)}</td>
        <td>{displayDeviceStatusColumnDetails(device)}</td>
        <td>{displayDataWithTooltip(device?.displayLocation)}</td>
        <td>
          {displayDataWithTooltip(
            device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER ||
              device?.deviceType?.toUpperCase() === DeviceTypeEnum.WAVE
              ? '-'
              : device?.displayArea,
          )}
        </td>
        <td>{displayApplicationsColumnDetails(device)}</td>
      </tr>
    );
  };

  const locationDropdownItems =
    locationDetails?.length > 0
      ? locationDetails.map((location) => ({
          id: location.locationId,
          value: location.locationName,
        }))
      : [
          {
            id: 'location-0',
            value: constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT,
          },
        ];

  const displayChildDetails = (device) => {
    return (
      <>
        {device?.isExpanded && device?.childDevices?.length
          ? device?.childDevices?.map((childDevice) => (
              <>
                {displayDeviceRowDetails(childDevice)}
                <>
                  {childDevice?.childDevices?.length ? (
                    <>{displayChildDetails(childDevice)}</>
                  ) : null}
                </>
              </>
            ))
          : null}

        {device?.isExpanded &&
        device?.childPageDetails?.page + 1 <
          device?.childPageDetails?.totalPages &&
        device?.childDevices?.length ? (
          <tr>
            <div
              className={`load-more ${
                device?.childPageDetails?.page + 1 >=
                device?.childPageDetails?.totalPages
                  ? 'load-more-disabled'
                  : ''
              } load-more-child`}
              role="button"
              onClick={() => {
                if (
                  device?.childPageDetails?.page + 1 <
                  device?.childPageDetails?.totalPages
                ) {
                  setExpandedParent(device);
                }
              }}
              style={{
                marginLeft: device?.stepCount
                  ? `${device?.stepCount * 40 + 40}px`
                  : '',
              }}
            >
              {expandedParent?.deviceId === device?.deviceId &&
              device?.childDevices?.length ? (
                <span className="margin-rt-4 d-flex">
                  <SiteSpinner
                    width="16px"
                    height="16px"
                    backgroundColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--brand_black')}
                  />
                </span>
              ) : (
                <BsPlus
                  size={20}
                  disabled={
                    device?.childPageDetails?.page + 1 >=
                    device?.childPageDetails?.totalPages
                  }
                />
              )}
              {constants.DEVICES_HEADER_BUTTON_LOAD_MORE}
            </div>
          </tr>
        ) : null}
      </>
    );
  };

  const displayGatewayDetails = () => {
    return (
      <>
        {!filteredDeviceData?.length && showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="loader-container">
              <SiteSpinner width="60px" height="60px" />
            </td>
          </tr>
        ) : filteredDeviceData?.length ? (
          <>
            {filteredDeviceData?.map((device, index) => (
              <Fragment key={device?.deviceId}>
                {index < (pageDetails?.page + 1) * 100
                  ? displayDeviceRowDetails(device)
                  : null}
                {displayChildDetails(device)}
              </Fragment>
            ))}
            {pageDetails?.page + 1 < totalPages ? (
              <tr>
                <div
                  className={`load-more ${
                    pageDetails?.page + 1 >= totalPages
                      ? 'load-more-disabled'
                      : ''
                  } load-more-parent`}
                  role="button"
                  onClick={() => {
                    if (pageDetails?.page + 1 < totalPages) {
                      onchangePageNo(pageDetails?.page + 1);
                      setShowLoaderMore(true);
                    }
                  }}
                >
                  {showLoaderMore ? (
                    <span className="margin-rt-4 d-flex">
                      <SiteSpinner
                        width="16px"
                        height="16px"
                        backgroundColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--brand_black')}
                      />
                    </span>
                  ) : (
                    <BsPlus
                      size={20}
                      disabled={pageDetails?.page + 1 >= totalPages}
                    />
                  )}
                  {constants.DEVICES_HEADER_BUTTON_LOAD_MORE}
                </div>
              </tr>
            ) : null}
          </>
        ) : !showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="no-device-container">
              <img
                alt="exclamation"
                src={CircleExclamation}
                width={'60px'}
                height={'60px'}
              />
              <div className="no-device-content mt-3">
                <div className="no-device-heading">
                  {constants.SCAN_NETWORK_NO_DEVICE_TITLE}
                </div>
                <div className="no-device-text">
                  {constants.DEVICES_TABLE_NO_DEVICES_TEXT}
                </div>
              </div>
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  const getHeaderStyle = (id) => {
    if (id === 'arrow' || id === 'checkbox') {
      return 'fixed-column';
    }
    if (id === 'applications-linked') {
      return 'header-applications-linked';
    }
    return null;
  };

  const displayHeaderDetails = (headerList) => {
    return (
      <tr>
        {headerList?.map((heading) => (
          <th className={getHeaderStyle(heading?.id)} key={heading?.id}>
            <div className={heading?.id}>
              {heading?.displayName}
              {heading?.id === 'arrow' ||
              heading?.id === 'applications-linked' ||
              heading?.id === 'location' ||
              heading?.id === 'area' ? null : heading?.id === 'checkbox' ? (
                <Checkbox
                  checked={statusOfAllDevicesSelected()}
                  onClick={(e) => {
                    const { checked } = e.target;
                    if (checked) {
                      onSelectAllDevices();
                    } else {
                      resetSelectedDevices();
                    }
                  }}
                />
              ) : pageDetails.orderBy === heading?.id && pageDetails.isAsc ? (
                <IoIosArrowRoundDown
                  size={20}
                  color="#0000004A"
                  role="button"
                  onClick={() => onSort(heading)}
                />
              ) : pageDetails.orderBy === heading?.id && !pageDetails.isAsc ? (
                <IoIosArrowRoundUp
                  size={20}
                  color="#0000004A"
                  role="button"
                  onClick={() => onSort(heading)}
                />
              ) : (
                <RxCaretSort
                  size={20}
                  color="#0000004A"
                  role="button"
                  onClick={() => onSort(heading)}
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    );
  };

  const LoaderOverlay = () => {
    return <div className="position-fixed l-48 loader-style"></div>;
  };

  return (
    <div className="App">
      <Header breadcrumbData={breadList} className="Desktop" />
      <LoadingOverlay
        active={showLoader && !showLoaderMore}
        spinner={LoaderOverlay()}
      >
        <div className="paginated-device-page">
          <div className="paginated-devices">
            <div className="devices-top-area">
              <div className="devices-header">
                <div className="devices-title">
                  {isEditMode ? (
                    <div
                      role="button"
                      className="back-mode-title"
                      onClick={() => {
                        setIsEditMode(false);
                        resetSelectedDevices();
                        setSelectedDeviceToRemove(null);
                        setRenamingDevice(null);
                        setIsCheckForSearchCSS(!isCheckForSearchCSS);
                      }}
                    >
                      <div>
                        <HiOutlineChevronLeft
                          size={24}
                          color="#000000"
                          className="margin-rt"
                          role="button"
                        />
                        {constants.DEVICES_HEADER_BUTTON_EDIT}
                      </div>
                      <div>
                        <IoMdClose />
                      </div>
                    </div>
                  ) : (
                    constants.DEVICES_PAGE_TITLE
                  )}
                </div>
                <BreadcrumbList
                  list={Utils.CheckBreadcrumbForUserRole(
                    isEditMode ? editModeBreadList : breadList,
                  )}
                  isFromEditDevices={isEditMode}
                  callBack={() => {
                    setIsEditMode(false);
                    resetSelectedDevices();
                    setIsCheckForSearchCSS(!isCheckForSearchCSS);
                  }}
                />
              </div>
              {discovredDevciesCount &&
              getCustomerOrgPolicies()?.install_device &&
              getCustomerOrgPolicies()?.claim_device ? (
                <div className="discovred-devices-container">
                  <div className="d-flex">
                    <HiOutlineInformationCircle size={20} />
                    <div className="deiscovered-device-message">
                      {constants.DISCOVERED_DEVICES_MESSAGE}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div
                      className="pending-count"
                      onClick={() => {
                        navigate(
                          `/devices/discovered-devices.html?orgId=${orgDetails?.orgId}`,
                        );
                      }}
                      role="button"
                    >
                      {discovredDevciesCount}{' '}
                      {constants.DISCOVERED_DEVICES_PENDING_COUNT}
                      <HiOutlineChevronRight size={20} color="#F37321" />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="devices-filters">
                <div className="left-side">
                  {isEditMode ? (
                    <div
                      className={`reload-filter-edit ${
                        !selectedDevices?.length ? 'disabled' : ''
                      }`}
                      role="button"
                      onClick={() => {
                        setIsDisplayLocationAreaModal(true);
                        setUpdateUserMessage(null);
                      }}
                    >
                      <TbMapPin size={18} />
                      {constants.DEVICES_HEADER_BUTTON_SET_LOCATION}
                    </div>
                  ) : (
                    <SearchableDropDown
                      isFromDeviceList={true}
                      isLocationDropDown={true}
                      isLocationDisabled={false}
                      isPersistLocation={true}
                      onSelectLocationHandler={onSelectLocationHandler}
                      setLocationDetails={setLocationDetails}
                      setPreSelectedLocation={setSelectedLocation}
                    />
                  )}
                  {!isEditMode ? (
                    <div
                      className="reload-filter-edit"
                      role="button"
                      onClick={() => {
                        setIsEditMode(true);
                        resetSelectedDevices();
                        setSelectedDeviceToRemove(null);
                        setIsCheckForSearchCSS(!isCheckForSearchCSS);
                      }}
                    >
                      <IconEdit width={20} height={20} />
                      {constants.DEVICES_HEADER_BUTTON_EDIT}
                    </div>
                  ) : null}
                  <div className="count-container">
                    <div className="display-bold">
                      {filteredDeviceData?.length}
                    </div>
                    <div>of {totalDevices}</div>
                  </div>
                  {isEditMode ? (
                    <div className="count-container">
                      ({' '}
                      <div className="paginated-claiming-device">
                        {selectedDevices?.length}{' '}
                      </div>
                      <div> {constants.DEVICES_HEADER_SELECTED}</div>
                    </div>
                  ) : null}
                  {updateUserMessage ? (
                    <div className="update-location-usermsg-container">
                      {updateUserMessage?.succeed?.length &&
                      !updateUserMessage?.failed?.length ? (
                        <div className="success">
                          {updateUserMessage?.succeed?.length === 1
                            ? constants.DEVICES_HEADER_SUCCESS1.replace(
                                '{deviceCount}',
                                updateUserMessage?.succeed?.length,
                              )
                            : constants.DEVICES_HEADER_SUCCESS2.replace(
                                '{deviceCount}',
                                updateUserMessage?.succeed?.length,
                              )}
                        </div>
                      ) : !updateUserMessage?.succeed?.length &&
                        updateUserMessage?.failed?.length ? (
                        <div className="failure">
                          {updateUserMessage?.succeed?.length}{' '}
                          {constants.DEVICES_HEADER_FAILED}
                        </div>
                      ) : updateUserMessage?.succeed?.length &&
                        updateUserMessage?.failed?.length ? (
                        <>
                          <div className="success">
                            {updateUserMessage?.succeed?.length === 1
                              ? constants.DEVICES_HEADER_SUCCESS1.replace(
                                  '{deviceCount}',
                                  updateUserMessage?.succeed?.length,
                                )
                              : constants.DEVICES_HEADER_SUCCESS2.replace(
                                  '{deviceCount}',
                                  updateUserMessage?.succeed?.length,
                                )}
                          </div>
                          <div className="failure">
                            [ {updateUserMessage?.succeed?.length}{' '}
                            {constants.DEVICES_HEADER_FAILED}]
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="right-side">
                  <div className="search-container">
                    <IoIosSearch
                      className="search-icon"
                      size={24}
                      role="button"
                      onClick={() => {
                        resetSelectedDevices();
                        setUpdateUserMessage(null);
                        if (pageDetails?.page) {
                          setPageDetails(defaultPageDetails);
                        } else {
                          fetchGatewayData(searchText.trim());
                        }
                        if (ref.current) {
                          ref.current.scrollTo({ top: 0 });
                        }
                      }}
                    />
                    <input
                      type={'text'}
                      placeholder={'Search'}
                      className="search-input"
                      value={searchText}
                      onChange={(e) => setSearchText(e?.target?.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          resetSelectedDevices();
                          setUpdateUserMessage(null);
                          if (pageDetails?.page) {
                            setPageDetails(defaultPageDetails);
                          } else {
                            fetchGatewayData(e.target?.value);
                          }
                          if (ref.current) {
                            ref.current.scrollTo({ top: 0 });
                          }
                        }
                      }}
                    />
                    {searchText ? (
                      <IoCloseCircle
                        className="search-close"
                        size={16}
                        onClick={() => {
                          setSearchText('');
                          setSearchedText('');
                          fetchGatewayData('');
                        }}
                        color="#747E87"
                        role="button"
                      />
                    ) : null}
                  </div>
                  <div
                    className="reload-filter-edit"
                    role="button"
                    onClick={() => {
                      setIsDisplayFiltersModal(true);
                      setUpdateUserMessage(null);
                    }}
                  >
                    <IconFilter width={20} height={20} />
                    {constants.DEVICES_HEADER_BUTTON_FILTER}
                  </div>
                  <div
                    className="reload-filter-edit"
                    role="button"
                    onClick={() => onClickReset()}
                  >
                    <IconRefresh width={20} height={20} />
                    {constants.DEVICES_HEADER_BUTTON_RELOAD}
                  </div>
                </div>
              </div>
            </div>
            {filteredDeviceData?.length && showLoader && !showLoaderMore ? (
              <div className="position-absolute screen-loader-container l-48 t-52">
                <SiteSpinner width="60px" height="60px" />
              </div>
            ) : null}
            {customerOrgPolicies &&
              customerOrgPolicies?.filter(
                (item) => item.policyName === 'view_device',
              ) && (
                <div className="devices-bottom-area">
                  <div className="devices-bottom-area-contents" ref={ref}>
                    {isEditMode ? (
                      <EditDevices
                        displayHeaderDetails={displayHeaderDetails}
                        displayGatewayDetails={displayGatewayDetails}
                      />
                    ) : (
                      <ResizableTable
                        widths={[29, 420, 200, 296, 120, 140, 140, 241]}
                        minWidths={[29, 220, 140, 150, 110, 110, 110, 241]}
                        minWidth={29}
                        disabledColumns={[0, 6]}
                        displayHeaderDetails={() =>
                          displayHeaderDetails(paginatedHeaderList)
                        }
                        displayGatewayDetails={() => displayGatewayDetails()}
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
          <SiteModal
            modalTitle=""
            modalHeader={true}
            showModal={isDisplayFiltersModal}
            hideModal={() => {
              setIsDisplayFiltersModal(false);
            }}
            classes="device-filter-modal"
            key="device-filter-modal"
            wrapperClass="device-filter-modal"
            size={'lg'}
          >
            <DevicesFilters
              closeModal={() => setIsDisplayFiltersModal(false)}
              apiConnectTypes={deviceTypes}
              setFiltersData={(e) => {
                setPageDetails(defaultPageDetails);
                setFiltersData(e);
                resetSelectedDevices();
              }}
              filtersData={filtersData}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.DEVICES_HEADER_BUTTON_SET_LOCATION}
            showModal={isDisplayLocationAreaModal}
            hideModal={() => {
              setIsDisplayLocationAreaModal(false);
            }}
            classes="location-area-selector-modal"
            key="location-area-selector-modal"
            wrapperClass="location-area-selector-modal"
          >
            <LocationAreaSelectorModal
              closeModal={() => {
                setIsDisplayLocationAreaModal(false);
              }}
              listSelectedLocation={selectedLocation}
              updateDeviceLocationNArea={updateDeviceLocationNArea}
              isD2CInSelectedDevices={isD2CInSelectedDevices}
              selectedDevicesAreas={selectedDevicesAreas}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.DEVICES_MODAL_REMOVE_DEVICE}
            showModal={isDisplayRemoveDeviceModal}
            hideModal={() => {
              setIsDisplayRemoveDeviceModal(false);
              setSelectedDeviceToRemove(null);
            }}
            classes="location-area-selector-modal"
            key="remove-device-modal"
            wrapperClass="location-area-selector-modal"
          >
            <RemoveDeviceModal
              closeModal={() => {
                setIsDisplayRemoveDeviceModal(false);
                setSelectedDeviceToRemove(null);
              }}
              onRemoveDevice={onRemoveDevice}
              selectedDeviceToRemove={selectedDeviceToRemove}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.SCAN_NETWORK_MODAL_TITLE}
            showModal={showScanNetworkModal}
            hideModal={onCloseScanNetworkPopup}
            classes="scan-network-modal"
            key="scan-network-modal"
            wrapperClass="scan-network-wrapper"
            size={'xl'}
            modalFooter={
              <div
                className="primary-btn dark"
                role="button"
                onClick={() => onCloseScanNetworkPopup()}
              >
                {constants.SCAN_NETWORK_CLOSE_BTN_LABEL}
              </div>
            }
          >
            {showScanNetworkModal && (
              <ScanNetworkModal gatewayDeviceData={gatewayDeviceData} />
            )}
          </SiteModal>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default Devices;
