import { forwardRef } from 'react';
import { ErrorMessage, useField } from 'formik';

const TextField = forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const { label, ...otherProps } = props;

  return (
    <div
      className={`${props.removebottommargin ? '' : 'mb-1'} ${
        props.removetopmargin ? '' : 'mt-1'
      } row`}
    >
      {label && (
        <label
          className="col-sm-3 form-label col-form-label"
          htmlFor={field.name}
        >
          {label}
        </label>
      )}

      <div
        className={
          label
            ? `col-sm-7 ${meta.touched && meta.error && 'is-invalid'} ${
                props.beforeinputicon || props.afterinputicon
                  ? 'input-with-icons'
                  : 'position-relative'
              }`
            : `col ${meta.touched && meta.error && 'is-invalid'} ${
                props.beforeinputicon || props.afterinputicon
                  ? 'input-with-icons'
                  : 'position-relative'
              }`
        }
      >
        {props.beforeinputicon && (
          <div className="icon-before-input">{props.beforeinputicon}</div>
        )}
        <div>
          <input
            className={`form-control require shadow-none ${
              meta.touched && meta.error && 'is-invalid'
            } ${field.value ? 'has-value' : ''} 
              ${props.isDuplicateLocation ? 'is-duplicate' : ''}`}
            {...field}
            {...otherProps}
            ref={ref}
            placeholder=""
            beforeinputicon=""
            afterinputicon=""
          />
          <span
            className={`placeholder-wrapper ${
              props.required ? 'required' : ''
            } ${meta.touched && meta.error && 'is-invalid'}`}
          >
            {props.placeholder}
          </span>
        </div>
        {props.afterinputicon && (
          <div className="icon-after-input">{props.afterinputicon}</div>
        )}
      </div>
      {!props.hideError && (
        <ErrorMessage component="div" name={field.name} className="error" />
      )}
    </div>
  );
});
export default TextField;
